import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import {Section, SectionTitle, Container, SectionImageBkg, BreadCrumb } from "../components/Section"
import { HeroBanner, HeroTitle, HeroCaption, HeroContent, HeroLeft, HeroRight, HeroCard } from "../components/HeroBanner"
import JoinForm from "../components/JoinForm"
import CategoryCarousel from "../components/CategoryCarousel"
import StarRating from "../components/StarRating"

const CardDefault = styled.div`
  position:relative ;
  background: #f3f6fb;
  background: -moz-linear-gradient(top,  #f3f6fb 0%, #eaeff7 100%);
  background: -webkit-linear-gradient(top,  #f3f6fb 0%,#eaeff7 100%);
  background: linear-gradient(to bottom,  #f3f6fb 0%,#eaeff7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f6fb', endColorstr='#eaeff7',GradientType=0 );
  width: 100%;
  margin:0 auto;
  border:3px solid #fff;
  border-radius:20px;
  padding:20px;
  display:flex;
  flex-wrap:wrap;
  transition:all 0.5s ease;
  @media(min-width:992px){
    border-radius:30px;
    padding:30px;
  }
  &:hover{
    box-shadow: 0 30px 30px rgba(190,197,207,0.60);
  }
`
const CardLeft = styled.div`
  position:relative;
  width:100%;
  @media(min-width:768px){
    padding:0 15px;
    flex: 0 0 25%;
    max-width: 25%;
  }
  @media(min-width:992px){
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
`
const CardRight = styled.div`
  position:relative;
  width:100%;
  @media(min-width:768px){
    padding:0 15px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
`
const CardTitle = styled.strong`
  display:block;
  font-family: 'Montserrat', sans-serif;
  font-weight:600;
  letter-spacing: 0.5px;
  font-size:18px;
  line-height:24px; 
  margin-bottom:5px;
  @media(min-width:1200px){
    font-size:20px;
    line-height:30px; 
  }
  @media(min-width:1600px){
    font-size:26px;
    line-height:34px; 
  }
`
const CardBody = styled.div`
  margin:0;
  min-height:105px;
  > p{
    font-size:16px;
    line-height:28px; 
    font-weight:500;
    @media(min-width:1200px){
      font-size:18px;
      line-height:30px; 
    }
  }

`
const VectorImage = styled(SectionImageBkg)`
  top:auto;
  left:0;
  bottom:0;
  width: auto;
  height: auto;
`
const ShowReview = styled.div`    
  position: relative;
  background: #C20000;
  border-radius: 40px;
  text-align: center;
  box-shadow:inset 2px 8px 0 rgba(0,0,0,0.16);
  padding: 0 12px 5px;
  width: fit-content;
  margin-bottom:15px;
  @media(min-width:768px){
    margin-bottom:30px;
  }
`

const ListReview = styled.div`
  margin:0;
`
const ListItemReview = styled.div`
  + div{
    margin-top:30px;
    @media(min-width:1200px){
      margin-top:40px;
    }
    @media(min-width:1600px){
      margin-top:60px;
    }
  }
`

const ReviewsPage = ({ data, location }) => {
  const allCategories = data.allContentfulCategory.edges
  const pageData = data.contentfulPageReviews
  const allReviews = data.allContentfulReview.edges

  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section bgColor="#dbe1e9" bgBefore="#002765" bgBeforeHeight="80%"  bgBeforeWidth="100%">
        <HeroBanner className="banner-hero" bgBefore="block" data-shadowtext={pageData.heroTitle}>
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb><Link to='/'>Home</Link><span>Reviews</span></BreadCrumb>
              <HeroContent>
                <HeroLeft><HeroTitle>{pageData.heroTitle}</HeroTitle></HeroLeft>
                <HeroRight><HeroCard><JoinForm location={location} /></HeroCard></HeroRight>
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Section>

      <Section ept="80px" epb="160px" xpt="80px" xpb="120px" pt="60px" pb="80px" bgColor="#dbe1e9" className="section-reivews">
        <VectorImage><StaticImage src="../images/vector-art.png" alt="vector-art" /></VectorImage>
        <Container>
          <SectionTitle mb="30px">Trusted by Many</SectionTitle>
            <ListReview>
              {
                allReviews.map((item, i) =>(
                  <ListItemReview>
                    <CardDefault>
                      <CardLeft>
                        <CardTitle>{item.node.reviewWriter}</CardTitle>
                        <ShowReview>
                          <StarRating name="mr1" ragingId1="r1" ragingId2="r2" ragingId3="r3" ragingId4="r4" ragingId5="r5"  size="sm" reviewStar={item.node.star} />
                        </ShowReview>
                      </CardLeft>
                      <CardRight>
                        <CardBody>
                          <div dangerouslySetInnerHTML={{__html: item.node.review.childMarkdownRemark.html}} />
                        </CardBody>
                      </CardRight>
                    </CardDefault>
                  </ListItemReview>
                ))
              }
            </ListReview>
        </Container>        
      </Section>

      <Section ept="120px" epb="120px" xpt="80px" xpb="80px" pt="40px" pb="40px" bgColor="#fff"  className="section-buy">
        <SectionImageBkg height="50%" bgColor="#161420" opacityImg="0.3"><StaticImage src="../images/black-vector.jpg" alt="black-vector" /></SectionImageBkg>
        <CategoryCarousel data={allCategories} current="all" />
      </Section>
    </Layout>
  )
};

export default ReviewsPage

export const pageQuery = graphql`
  query ReviewPageQuery {
    contentfulPageReviews {
      metaTitle
      metaDescription
      heroTitle
    }
    allContentfulReview {
      edges {
        node {
          review {
            childMarkdownRemark {
              html
            }
          }
          reviewWriter
          star
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          name
          url
          mainImage {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
  }
`
